.site-sticky
	clearfix()
	border-bottom 3px solid #bcaf91
	position relative
	z-index 20
	.item
		&-logo
			float left
			margin 20px 0
			h1
				font-size 0
		&-menu
			float right
