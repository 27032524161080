.site-copy
	background-color $c-dark
	padding 20px 0
	clearfix()
	p
		margin 0
		color #fff
		font-size 16px
		text-transform uppercase
		a
			color #fff
			text-decoration none
			&:hover
				text-decoration underline
	.item
		&-left
			float left
		&-right
			float right
