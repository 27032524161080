.bx
	&-wrapper
		position relative
	&-controls-direction
		absolute left right top 50%
		transform translateY(-50%)
	&-prev
	&-next
		size 20px 40px
		font-size 0
		background url('../images/bx-control.png') left center no-repeat
		background-size 100%
		opacity .25
		transition .4s ease opacity
		&:hover
			opacity .5
		&.disabled
			opacity 0
			pointer-events none
	&-prev
		float left
		margin-left 15px
	&-next
		float right
		margin-right 15px
		transform rotate(180deg)
	&-image
		height 150px
		background-position center center
		background-repeat no-repeat
		background-size cover
	&-pager
		font-size 0
		absolute right 15px bottom 10px
		&-item
			display inline-block
			vertical-align top
			margin-left 5px
		&-link
			display block
			size 10px
			background-color #fff
			border-radius 50%
			opacity .5
			&.active
			&:hover
				opacity 1
	&slider-item
		list-style none
		display none
		img
			margin auto
		&:first-child
		.bx-wrapper &
			display block
	@media $768
		&-prev
		&-next
			size 39px 63px

