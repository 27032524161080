.container
	margin auto 10px
	max-width $container
	&.view-s
		max-width 600px
		margin 0 auto
	@media $600
		margin auto 30px
	@media $1200
		margin auto
