.reference
	background-color $c-dark
	overflow hidden
	width 100%
	position relative
	&-image
		width 100%
		height 200px
		border 5px solid $c-dark
		background-size cover
	&-content
		padding 30px 0 30px 30px
		color #fff
		.item
			&-title
				text-transform uppercase
				color #A0A09E
				font-size 12px
				margin-bottom 10px
			&-data
				font-size 18px
				margin-bottom 10px
				&:last-child
					margin-bottom 0
	&-label
		background-color #655E4E
		absolute right -40px top 15px
		color #fff
		text-transform uppercase
		padding 5px 60px
		transform rotate(35deg)

	@media $1000
		&-image
		&-content
			float left
			width 50%
		&-image
			absolute left top bottom
			height auto
		&-content
			margin-left 50%
		&-label
			transition .2s ease all
			&:hover
				background-color $c-primary

