.products
	background-color #EDEDEC
	border-bottom 3px solid #D9D9D9
	font-size 0
	&-list
		text-align center
		padding 40px 0
	&-item
		width 33.33333%
		display inline-block
		vertical-align top
		text-decoration none
		margin-bottom 10px
		.item
			&-image
				display block
				margin auto
				transition .1s ease transform
				transform translateZ(0)
			&-name
				margin 0
				text-align center
				font-size 14px
				color #807762
				font-weight bold
				text-transform uppercase
				text-decoration none
				position relative
				z-index 10
				transition .1s ease color

	@media $768
		&-list
			padding 40px 0 50px
		&-item
			width 12.5%
			margin-bottom 0

	@media $1000
		&-item
			&:hover
				.item
					&-image
						transition .4s ease transform
						transform scale(1.1)
					&-name
						color $c-primary-dark
						transition .4s ease color
