.site-content
	padding 40px 0
	clearfix()
	&.view-page
		padding 80px 0
	&-right
		padding-top 40px
	@media $768
		&-left
			float left
			width 40%
		&-right
			float left
			width 60%
			padding 0
			padding-left 60px
