.quickcontact
	&-header
		background-color $c-dark
		color #fff
		position relative
		padding 15px 0 15px 75px
		&:before
			content ''
			absolute left top bottom
			width 60px
			background url(../images/icon-mail.png) center center no-repeat #3f3a2e
		a
			text-transform uppercase
			font-size 15px
			text-decoration none
			&:hover
				text-decoration underline
	&-item
		width 100%
		border-collapse collapse
		color #fff
		tr:nth-child(odd)
			.item
				&-name
					background-color #8B733D
				&-icons
					background-color #A88E4A
				&-data
					background-color #B1954E
		tr:nth-child(even)
			.item
				&-name
					background-color #7C6737
				&-icons
					background-color #9C8245
				&-data
					background-color #A28848
		td
			padding 20px 10px
			text-transform uppercase
			font-size 15px
			line-height 1.8
		.item
			&-name
				padding 20px 25px
				width 80px
			&-icons
				width 50px
				background-repeat no-repeat
				background-position center center
				&.view-man
					background-image url(../images/quickcontact-man.png)
				&.view-woman
					background-image url(../images/quickcontact-woman.png)
			&-data
				a
					text-decoration none

