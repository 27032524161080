html
body
	background-color #fff
body
	color $c-text
	font-family $f-primary
	margin 0
	font-size 15px
	line-height 1.4

img
	max-width 100%
	height auto
	display block

a
	color inherit
	outline none
