.adressbox
	background-color #34322D
	padding 20px
	overflow hidden
	color #fff
	&-left
		p
			font-size 16px
			line-height 1.8
			margin 0
			font-family $f-secondary
			b
				font-weight bold
				font-size 19px
				line-height 1.4
	&-right
		padding-top 20px
		iframe
			max-width 100%
	@media $768
		padding 40px
		&-left
			float left
			width 30%
			border-left 4px solid rgba(#fff, .5)
			padding-left 30px
			margin-top 25px
		&-right
			float right
			width 70%
			padding-top 0
