*, *:before, *:after
	box-sizing border-box

// responsive iframe
.embed
	position relative
	padding-bottom 56.25%
	height 0
	overflow hidden
	max-width 100%
	height auto

	iframe, object, embed
		position absolute
		top 0
		left 0
		width 100%
		height 100%

.access
	font-size 0

.message
	padding 10px 15px
	font-size 18px
	&.view-success
		background-color #27ae60
		color #fff
