.menu
	list-style none
	padding 0
	margin 0
	&-toggle
		background-color $c-primary
		z-index 10
		padding 7px 20px 8px
		cursor pointer
		transition .2s ease all
		position relative
		z-index 5
		border-radius $radius
		overflow hidden
		margin-top 19px

		.item
			width 35px
			height 4px
			background-color #fff
			margin 6px 0
			display block
			position relative
			transition .2s ease all

		.menu-is-open &
			.item
				&:nth-child(1)
					transform rotate(45deg)
					top 10px
				&:nth-child(2)
					opacity 0
				&:nth-child(3)
					transform rotate(-45deg)
					bottom 10px

		@media $1000
			display none
	&-item-wrap
		float right
	&-toggle-label
		text-transform uppercase
		font-size 24px
		color #fff
		float left
		margin-top 2px
		margin-right 15px
	&-item
		display block
		position relative
		a
			display block
			text-decoration none
			font-size 17px
			font-weight 400
			text-transform uppercase
			position relative
			color $c-text

	.sub-menu
		padding 0
		margin 0
		list-style none

	@media $1000max
		z-index 20
		transition .4s ease all
		background-color #fff
		display none
		absolute left right top 75px
		padding 20px

		.menu-is-open &
			display block

		&-item
			text-align center
			a
				border-bottom 3px solid rgba(#000, .2)
		.sub-menu
			.menu-item
				a
					border-bottom 1px solid rgba(#000, .1)

	@media $768max
		&-item
			a
				padding 10px 0
				font-size 24px
		.sub-menu
			.menu-item
				a
					font-size 18px

	@media $1000max
		&-item
			a
				padding 15px 0
				font-size 26px
		.sub-menu
			.menu-item
				a
					font-size 20px

	@media $1000
		.sub-menu
			absolute left right top 100%
			text-align center
			overflow hidden
			opacity 0
			pointer-events none
			transition .4s ease all
			.menu-item
				display block
				a
					padding 10px
					font-size 14px
					color #fff
					background-color #35332D
					text-align center
					line-height 1.4
				&:hover
					a
						background-color #4B483F
						color #e2d6ba
				&:last-child
					a
						border-radius 0 0 $radius $radius
		&-item
			display inline-block
			vertical-align top
			a
				padding 0 20px
				line-height 110px
				transition .4s ease all
			&:hover
				> a
					background-color #ececec
					color #000
				.sub-menu
					opacity 1
					pointer-events auto
			&.current_page_item
			&.current-menu-item
			&.current-menu-parent
				> a
						font-weight bold
