.doubleblock
	overflow hidden
	&-left
	&-right
		margin 15px 0
	@media $768
		&-left
		&-right
			margin 0
		&-left
			width 50%
			float left
		&-right
			width 45%
			float right
	@media $1000
		&-left
			width 65%
		&-right
			width 30%
