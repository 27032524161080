.site-heading
	height 5px
	background-color $c-primary
	text-align center
	position relative
	h2
		absolute left right top 50%
		text-align center
		height 40px
		margin 0
		margin-top -20px
		padding 0
		span
			display inline-block
			background-color $c-primary-light
			text-transform uppercase
			font-size 15px
			padding 0 18px
			line-height 36px
			border-radius $radius
			border 2px solid $c-primary

