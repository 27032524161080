.field
	display block
	border 0
	padding 15px
	color $c-text
	border-radius 0
	appearance none
	font-size 16px
	border 2px solid $c-primary
	background-color #fff
	transition .2s ease all

	&::placeholder
		opacity 1
		color $c-text

	&:hover
	&:focus
		border-color $c-primary-dark
