.map
	height 260px
	background url(../images/map.jpg) center top no-repeat
	border-top 4px solid #e5e3de
	display block
	text-align center
	position relative
	text-decoration none
	.container
		position relative
	&-pin
		display block
		size 42px 57px
		background url(../images/map-pin.png) center center no-repeat
		absolute left right top 40px
		margin auto
	&-labels
		padding-top 110px
		font-size 0
	&-label
		text-transform uppercase
		color #fff
		background-color $c-primary
		font-size 16px
		display inline-block
		padding 5px 10px
		margin-bottom 10px
		position relative
		transition .4s ease all

		&:nth-child(1)
			background-color #A77751
		&:nth-child(2)
			background-color #655E4E
		&:nth-child(4)
			background #C1A356

	@media $768
		&-labels
			padding-top 60px
		&-label
			margin-bottom 50px
			&:nth-child(1)
				margin-right 70px
			&:nth-child(2)
				margin-left 70px
				left 40px

	@media $1000
		&:hover
			.map
				&-pin
					animation pulse 1s infinite

