.gallerybox
	margin 25px 0
	&-item
		display block
		position relative
		text-decoration none
		margin-bottom 5px
		.item
			&-btn
				absolute right top 20px
				padding 10px 15px
				color #fff
				background-color #A28848
				text-transform uppercase
				font-size 12px
			&-image
				overflow hidden
				img
					display block
					width 100%
					transition .1s ease all
			&-title
				absolute left right bottom
				padding 10px 0
				text-align center
				color #fff
				text-transform uppercase
				transition .1s ease all
				background-color rgba($c-dark, .9)
		&:nth-child(1n)
			.item-btn
				background-color rgba(#A28848, .95)
		&:nth-child(2n)
			.item-btn
				background-color rgba(#975D3F, .95)
		&:nth-child(3n)
			.item-btn
				background-color rgba(#A77751, .95)

	&.view-offset
		margin 40px 0 0

	@media $768
		&-list
			display flex
			flex-wrap wrap
			justify-content space-between
		&-item
			width 30%
			display inline-block
			vertical-align top
			margin-bottom 30px
			.view-row &
				margin-bottom 0
		&.view-offset
			margin 70px 0 20px

	@media $1000
		&-item
			&:hover
				.item
					&-image
						img
							transition .4s ease all
							transform scale(1.1) rotate(2deg)
					&-title
						transition .4s ease all
						background-color $c-dark
