.contactbox
	background-color #EDEDEC
	border-bottom 3px solid #D9D9D9
	font-size 0
	&-list
		text-align center
		padding 30px 0 50px
	&-block
		font-size 18px
		text-align center
		padding-bottom 20px
		.item
			&-title
				text-transform uppercase
				color #fff
				background-color $c-primary
				font-size 22px
				display inline-block
				padding 5px 10px
				font-weight normal
				margin-bottom 15px
				position relative
				transition .4s ease all
			&-name
			&-contact
				text-transform uppercase
				padding 0 10px
			&-name
				font-size 15px
			&-contact
				font-size 19px
				font-weight bold
				a
					text-decoration none
		&:nth-child(1n)
			.item
				&-title
					background-color #C18857
		&:nth-child(2n)
			.item
				&-title
					background-color #AA7859
		&:nth-child(3n)
			padding-bottom 0
			.item
				&-title
					background-color #BFA268
	@media $768
		&-list
			display flex
			flex-wrap wrap
			justify-content space-between
		&-block
			width 33.33333%
			display inline-block
			vertical-align top
			margin-bottom 0
			padding-bottom 0
			text-align left
			.item
				&-title
					font-size 30px
			&:nth-child(3n)
				text-align right
			&.view-middle
				text-align center
				border-left 1px solid rgba(#000, .1)
				border-right 1px solid rgba(#000, .1)
	@media $1000
		&-block
			.item
				&-contact
					a:hover
						color $c-primary-dark
