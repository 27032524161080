.basic-styles
	color $c-text
	font-family $f-secondary
	strong
	b
		color $c-text-dark
	p
		margin 0 0 20px
	:first-child
		margin-top 0
	table
		border-collapse collapse
		margin 15px 0
		td
		th
			padding 10px 20px
			border 1px solid $c-primary
	a
		color $c-primary-dark
		&:hover
			color $c-primary

	&.view-claim
		text-align center
		p
			font-size 22px

	@media $768
		h3
			font-size 40px
		p
			font-size 16px

