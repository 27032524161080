.gallery
	.gallery
		&-item
			padding-bottom 10px
			figcaption
				display none
		&-icon
			margin 5px 5px 0
			a
				display inline-block
				border 3px solid $c-primary
				&:hover
					border-color $c-primary-dark
			img
				margin 0
				padding 0
				max-width 100%
				display block
