.btn
  border 0
  text-decoration none
  background-color transparent
  display inline-block
  transition .2s ease all
  font-family $f-primary
  border 1px solid transparent
  cursor pointer

  &.view-primary
    background-color $c-primary
    color #fff
    padding 5px 15px 5px
    font-size 16px
    text-transform uppercase

    &:hover
      background-color lighten($c-primary, 10%)
      color #fff
